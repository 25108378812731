import gql from 'graphql-tag'

export const CreateCode = gql`
  mutation createCode($input: CreateCodeInput!) {
    createCode(input: $input) {
      success
    }
  }
`

export const ValidateCode = gql`
  mutation validateCode($input: ValidateCodeInput!) {
    validateCode(input: $input) {
      success
    }
  }
`
